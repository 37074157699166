import React from "react"
import PropTypes from "prop-types"
import Cookies from "../components/cookies/cookies"

import "./layout.css"

const Layout = ({ children }) => {

  return (
    <main>
      <div>{children}</div>
      <Cookies/>
    </main>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
