import React from "react"
import CookieConsent from "react-cookie-consent"

const Cookies = () => {

    return(
        <CookieConsent
            location='bottom'
            buttonText={"Aceitar"}
            cookieName='cookie'
            style={{ 
                background: '#02374e',
                justifyContent: 'center',
                alignItems: 'center',
                borderTop: '1px solid #fff',
                zIndex: '98'
            }}
            contentStyle={{
                fontFamily: 'arial, serif',
                textTransform: 'uppercase',
                letterSpacing: '1px',
                fontSize: '11px',
                lineHeight: '15px',
                color: '#fff',
                maxWidth: 'fit-content',
                textAlign: 'justify'
            }}
            buttonStyle={{ 
                color: '#000000',
                background: '#fff',
                fontFamily: 'arial, serif',
                textTransform: 'uppercase',
                letterSpacing: '1px',
                fontSize: '11px',
                lineHeight: '15px',
                padding: '10px 20px'
            }}
            expires={7}
        >
            Este site utiliza cookies para permitir uma melhor experiência por parte do utilizador. Ao navegar no site estará a consentir a sua utilização.
        </CookieConsent>
    )
}

export default Cookies